.show-view {
  height: 100%;

  @media (min-width: 768px) {
    width: 100% !important;
    margin-top: 0px;
  }

  .sr-banner {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;
  }

  .sr-banner-white {
    width: 100%;
    height: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;

    img {
      filter: invert(1);
    }
  }
  .progress-container {
    position: absolute;
    width: 100%;
    padding: 1rem 1rem 0.3rem 1rem;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 4;
    // background: rgb(0,0,0);
    // background: linear-gradient(0deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.30) 20%, rgba(0,0,0,0) 100%);
    @media (max-width: 991px) {
      width: calc(98% - 90px);
    }
  }
  .progress {
    display: flex;
    z-index: 100;
    height: 6px;
    width: 100%;
    border-radius: 10px;
    background: rgba(255,255,255,0.5);
    cursor: pointer;
  }
  .progress-timing {
    padding: 0 0 4px 5px;
    .font-sans-serif;
    font-size: 11px;
    color: white;
  }
  .progress-filled {
    width: 50%;
    background: #000000c9;
    flex: 0;
    flex-basis: 0%;
  }

  padding: 0 !important;
  .container();

  .desktop {
    &.video-overlay .show-container .message-container {
      margin-top: 40px;
      bottom: 0;
      overflow: auto;
      width: 100%;

      .box {
        background-color: transparent;

        &.influencer-message {
          background-color: #000000b0;
        }
      }
    }

    .collection-info {
      display: none;
    }

    #chat {
      .font-sans-serif;
      font-size: 12px;
    }

    #chat-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .wrapper-chat {
        padding: 10px 15px 20px;
      }
    }
  }

  .mobile {
    .video {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .show-container {
      position: fixed;
      top: 0;
      left: 0;
      bottom: inherit !important;
      height: 100% !important;
      width: 100vw;
    }

    div.rounded {
      left: 15px !important;
      top: 55px !important;
      right: inherit !important;
    }

    #collection-container {
      display: none !important;
    }
  }

  .tablet {
    // .video {
    //   width: 100vw;
    //   height: 100%;
    //   object-fit: cover;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
    .video {
      height: 100%;
      width: auto;
      aspect-ratio: .5625;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
      &.back {
        width: 120%;
        height: auto;
        left: -10%;
        z-index: 0;
        -webkit-filter: blur(1.7rem);
        -moz-filter: blur(1.7rem);
        -o-filter: blur(1.7rem);
        -ms-filter: blur(1.7rem);
        filter: blur(1.7rem);
      }
      // .modal-backdrop.in {
      //   filter: alpha(opacity=50);
      //   opacity: 1;
      // }
      // .modal-backdrop {
      //   background-color: white;
      // }
    }

    .show-container {
      position: fixed;
      top: 0;
      left: 0;
      bottom: inherit !important;
      height: 100% !important;
      width: 100vw;
    }

    div.rounded {
      left: 15px !important;
      top: 55px !important;
      right: inherit !important;
    }

    #collection-container {
      display: none !important;
    }
  }

  #video-container {
    margin: 0 auto;
    position: relative;
    height: 100%;
    width: 100vw;

    @media (min-width: 768px) {
      z-index: 3;
      overflow: hidden;
      position: fixed;
      height: 100vh !important;
      width: 100%;
    }

    .link-copied-message {
      position: absolute;
      top: 105px;
      right: 60px;
      background: #00000060;
      z-index: 20;
      color: #fff;
      .font-sans-serif(@font-size-small);
    }

    .big-play-button {
      .flaticon-solid-play;
      z-index: 3;
      position: absolute;
      background-color: #00000080;
      border-radius: 100px;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      font-size: 60px;
      padding: 0;
      opacity: 0.5
    }

    .play-pause-button {
      z-index: 4;
      position: fixed;
      background-color: red;
      border-radius: 100px;
      top: calc(50% - 40px);
      left: calc(50vw - 40px);
      .flaticon-solid-play;
      font-size: 80px;
      padding: 0;
      opacity: 0;
    }

    .big-pause-button {
      .flaticon-solid-pause;
      z-index: 3;
      position: absolute;
      background-color: #00000080;
      border-radius: 100px;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      font-size: 60px;
      padding: 0;
      opacity: .5;
      animation: 2s ease 2s normal forwards 1 fadein;
      -webkit-animation: 2s ease 2s normal forwards 1 fadein;
    }

    @keyframes fadein {
      0% {
        opacity: 0
      }
      30% {
        opacity: 0.3
      }
      40% {
        opacity: 0.4
      }
      80% {
        opacity: 0.4
      }
      100% {
        opacity: 0
      }
    }

    @-webkit-keyframes fadein {
      0% {
        opacity: 0
      }
      30% {
        opacity: 0.3
      }
      40% {
        opacity: 0.4
      }
      80% {
        opacity: 0.4
      }
      100% {
        opacity: 0
      }
    }
  }

  #primary-video-container {
    width: 100% !important;
    height: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: 768px) and (max-width: 1024px) {
      height: 100vh;
    }

    @media (max-width: 768px) {
      .video {
        width: 100vw;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .video-overlay {
    div.rounded {
      border-radius: 50%;
      z-index: 4;
      border: 1px solid white;
      background-size: cover;
      width: 75px;
      height: 75px;
      right: 15px;
      top: 15px;
    }

    .top-video {
      z-index: 4;
      top: 10px;
      width: 100% !important;
      display: flex;
      flex-direction: initial;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 991px) {
        padding-left: 40px;
      }

      h6 {
        max-width: 40%;
        margin: 4px 9px 0 !important;
        padding-bottom: 10px;
      }

      .timing {
        padding: 1px 10px 4px;
        color: white;
        background-color: rgba(0, 0, 0, 0.799);
        .font-sans-serif(@font-size-small);

        &.live {
          background-color: @brand-main-color;
        }
      }

      .followers {
        color: white;
        border-radius: 50px;
        float: right;
        padding: 1px 15px;
        .font-sans-serif(@font-size-small);
      }

      .like-video {
        color: white;
        border-radius: 50px;
        float: right;
        padding: 1px 15px;
        .font-sans-serif(@font-size-small);

        img {
          color: @brand-main-color;
        }
      }
    }

    .sticky-product {
      display: block;
      background-size: cover !important;
      background-position: center !important;
      background-color: white !important;
      border-radius: 50%;
      width: 85px;
      height: 85px;
      border: 1px solid white;
      box-shadow: -1px -1px 4px #00000042;
      position: absolute;
      top: 270px;
      right: 14px;
      z-index: 2;
      overflow: visible;

      .product-info {
        width: 100%;
        padding: 0;
        position: absolute;
        bottom: -18px;
        text-align: end;
        color: #333;
        line-height: 1;

        button {
          background-color: @brand-main-color;
          border-radius: 50%;
          padding: 0px 0 5px 1px;
          width: 24px;
          font-size: 20px;
          height: 24px;
          margin-bottom: 7px;
        }
      }
    }

    #secondary-video-container {
      width: 120px;
      height: 160px;
      position: absolute;
      right: 5px;
      bottom: 13vh;

      @media (min-width: 768px) {
        bottom: 0;
        // bottom: 5px;
      }

      z-index: 76;
      border-radius: 3px;

      .expand-video-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 77;
      }

      &.secondary-position {
        bottom: 60vh;
      }
    }

    .show-container {
      .message-container {
        position: relative;
        padding-right: 15px;
        padding-left: 15px;

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      @media (max-width: 992px) {
        height: 100% !important;

        .message-container {
          height: 270px;
          overflow-y: scroll;
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          padding-left: 0;

          .messages-container {
            position: relative;
            bottom: 0;
            width: 100%;
            display: flex;
          }
        }
      }

      .messages-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      .wrapper-chat {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        .chat-name-wrapper {
          position: absolute;
          width: calc(100% - 20px);
          min-width: 200px;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          span {
            .font-sans-serif;
            font-size: 11px;
            color: #0038FF;
            min-width: 100px;
            background: #fff;
            margin-right: 10px;
          }
          .chat-name {
            border: 1px solid #0038FF;
            background: #dce1f4;
            width: 100%;
            text-align: left;
            padding: 0 8px;
            height: 70%;
            font-size: 10px;
            .font-sans-serif;
            color: #0038FF;
            &::placeholder {
              font-size: 10px;
              .font-sans-serif;
              color: #0038FF;
            }
          }
          .chat-name-ok {
            display: flex;
            position: absolute;
            right: 13px;
            margin-right: 0;
            float: right;
            margin-top: 0;
          }
        }
        #chat {
          .font-sans-serif;
          font-size: 12px;
          &::placeholder {
            font-size: 12px;
            .font-sans-serif;
            font-style: italic;
            color: #8d8d8d;
          }
        }
      }

      .line-wrapper {
        width: 100%;
        background-color: #ffffffd6;
        height: 4px;
      }

      .container-input {
        background: #fff;
        padding: 0 10px;
        width: 100%;
        margin-right: 10px;
        border: 1px lightgray solid;
        z-index: 2;
        display: inline-block;
        height: 39px;
        border-radius: 18px;
        z-index: 1;
        position: relative;

        @media (max-width: 768px) {
          width: calc(100% - 90px);
          border: none;
        }

        input {
          background: transparent;
          height: 40px;
          border: none;
          width: calc(100% - 60px);

          &:focus-visible {
            outline: none;
          }

          &:-internal-autofill-selected {
            background-color: transparent !important;
          }

        }

        .message-ph {
          height: 40px;
          padding-top: 10px;
          padding-left: 10px;
          width: calc(100% - 60px);
          position: absolute;
        }

        img {
          margin-right: 5px;
          float: right;
          margin-top: 8px;
          cursor: pointer;
        }
      }

      .box {
        margin-bottom: 12px;
        background-color: #1d3c4aa3;
        position: relative;
        padding: 1px 18px;
        justify-content: flex-start;
        border-radius: 50px;
        align-items: center;
        display: flex;
        z-index: 1;
        width: max-content;
        max-width: 100%;

        &.influencer-message {
          background-color: #0000008f;
        }

        &.just-joined {
            margin-bottom: 0;
            margin-top: 0;
            background-color: transparent;
            opacity: 0.7!important;
            padding: 3px 20px;
        }

        h4 {
          .font-serif-italic;
          color: @brand-gray;
          font-size: 13px;
          position: relative;
          float: left;
          padding: 0;
          margin: 5px 5px 5px 0;
        }

        p {
          .font-sans-serif;
          color: white;
          font-size: 11.5px;
          position: relative;
          float: right;
          padding: 0;
          margin: 5px 0;
        }

      }

      .collection-info {
        padding: 25px;
        border-radius: 8px;
        background-color: white;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        z-index: 3;
        .make-responsive-width(35%, 100%, 100%, 100%);

        @media (min-width: 992px) {
          margin-bottom: 10px;
          position: absolute;
          top: 0;
          right: 0;
          margin: 30px;
          width: calc(35% - 60px) !important;
          overflow: auto;
          height: 92vh;
        }

        h2 {
          .font-sans-serif(16px);
          letter-spacing: 1px;
          text-transform: uppercase;
          margin: 0;
        }

        div.product-list {
          overflow: auto;
          height: 85vh;

          @media (max-width: 991px) {
            margin-top: 10px;
            max-height: 35vh;
          }
          .product-info {
            padding-top: 10px;
            clear: both;
            height: auto;
            @media (min-width: 992px) {
              width: 100% !important;
            }
            .container();
            img {
              width: 100%;
              .make-xs-column(3);
            }
            .product-brand {
              .make-xs-column(5);
              padding: 0
            }
            .btn-quick-view {
              .font-sans-serif(12px);
              color: @brand-main-color;
              letter-spacing: 0.7px;
              text-transform: uppercase;
              font-weight: bold;
              cursor: pointer;
              .make-xs-column(4);
            }
          }
          &::-webkit-scrollbar {
            -webkit-appearance: none;
            background-color: #f3f3f3;
          }
          &::-webkit-scrollbar:vertical {
            width:8px;
          }
          &::-webkit-scrollbar-button:increment,&::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar:horizontal {
            height: 7px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #dedede;
            border-radius: 20px;
          }
          &::-webkit-scrollbar-track {
            border-radius: 10px;
          }
        }
      }
    }

    .show-container-shadow {
      background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0.7) 90%),
        url(/img/bg-video.gif);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative !important;
      display: flex;
      flex-direction: row;

      #collection-container,
      #chat-container,
      #video-container {
        width: 35%;
        position: relative;
      }

      #collection-container.collection-info {
        display: flex;
        flex-direction: column;
      }

      #chat-container {
        bottom: 0 !important;
      }
    }

    #chat-container {
      position: fixed;
      bottom: 0;
      z-index: 4;
      width: 100%;
      align-items: flex-start;
      display: flex;
      padding: 0;
      flex-direction: column;
      height: 100vh;
      justify-content: flex-end;

      @media (max-width: 991px) {
        padding: 0;
        height: 275px;

        .wrapper-chat,
        .message-container {
          padding: 0 15px 0 7px;
          width: 98%;
        }

        .wrapper-chat {
          margin-bottom: 0;
          // margin-bottom: 10px;
        }
        &.posted-show {
          height: 295px;
          .wrapper-chat {
            justify-content: flex-end;
            margin-bottom: 4vh;
          }
          .message-container {
            width: calc(100% - 100px);
            bottom: -80px;
          }
          &.active-show {
            height: 195px;
            .message-container {
              bottom: -60px;
            }
            .box {
              margin-bottom: 9px;
            }
          }
        }
      }
    }

    .show-title {
      position: absolute;
      z-index: 1;
      padding: 13px 15px 40px;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.30) 50%, rgba(0, 0, 0, 0) 100%);
    }

    #mouseTarget {
      opacity: 1;
    }

    .opacity-module {
      animation: 3s ease 0s normal forwards 1 fadein1;
      -webkit-animation: 3s ease 0s normal forwards 1 fadein1;
      opacity: 1
    }

    @keyframes fadein1 {
      0% {
        opacity: 0.5
      }

      80% {
        opacity: 0
      }

      100% {
        opacity: 0
      }
    }

    @-webkit-keyframes fadein1 {
      0% {
        opacity: 0.5
      }

      80% {
        opacity: 0
      }

      100% {
        opacity: 0
      }
    }

    .top-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 10px;

      .show-influencer {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      h6 {
        .font-sans-serif-bold;
        .make-responsive-font-size(17px, 17px, 14px, 14px);
        color: white;
        padding-left: 10px;
        max-width: 70%;
      }

      .show-icons {
        float: right;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #00000050;
        padding: 5px 0 3px 0;
        border-radius: 16px;

        .circle-bg {
          padding: 1px 8px;
          border-radius: 50%;
          margin-bottom: 5px;
          cursor: pointer;

          &.black {
            background: #0000004f;
          }

          img {
            width: 20px;
          }
        }

      }
    }
    .show-caption{
      display: none;
    }

    &.with-caption {
      #chat-container.posted-show.active-show .message-container {
          bottom: -22px;
      }
    }
  }

  video::cue {
      display: block;
      position: absolute;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.388), rgba(0, 0, 0, 0.388));
      color: white;
      z-index: 1;
      font-family: sans-serif;
      font-size: 1.3rem;
      line-height: 2;
      width: auto;
  }

  .collection-info {

    p,
    .product-price {
      margin: 0 !important;
    }

    h6 {
      .font-sans-serif-bold;
    }

    .product-info {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  }

  @media (max-width: 992px) {
    .btn-product-show {
      position: relative;
      z-index: 3;
      background: #ffffffd6;
      bottom: -4vh;
      right: 0;
      padding: 10px 8px 5px 10px;
      line-height: 0.9;
      color: @brand-gray-dark;
      width: 90px;
      border-radius: 8px 8px 0 0;
      .font-sans-serif;
      font-size: 10px;
      &.live {
        bottom: 0;
      }
      span.icon:before {
        content: "\e1c1";
        font-family: "Flaticons Solid";
        speak: none;
        font-style: normal;
        font-weight: normal;
        line-height: 1.2;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
      }
      span.number{
        width: 20px;
        height: 20px;
        letter-spacing: 0px;
        background-color: @brand-main-color;
        border-radius: 16px;
        text-align: center;
        font-family: 'OMBR',Arial,sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
        display: inline-block;
        position: absolute;
        right: -6px;
        top: -6px;
        z-index: 3;
      }
      span:focus-visible {
        outline-color: red;
        outline-style: auto;
        outline-width: 5px;
      }
    }
  }
}

.show-quick-view-modal {
  .close-btn {
    z-index: 5;
    color: white;
    float: none;
    left: 0;
    right: inherit;
    cursor: pointer;

    &:hover {
      background-color: transparent !important;
    }
  }

  @media (max-width: 768px) {
    .modal-dialog {
      margin: 0;
    }
  }
}

.btn-container {
  position: relative;
  width: 78px;
  height: auto;
  cursor: pointer;

  .product-btn {
    position: absolute;
    width: 78px;
    height: 78px;
    border: 2px solid white;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
    }
  }

  span.number {
    background-color: @brand-main-color;
    color: white;
    border-radius: 50px;
    position: absolute;
    height: 18px;
    display: flex;
    width: 18px;
    top: 0;
    right: 0;
    align-content: center;
    justify-content: center;
    .font-sans-serif(@font-size-small);
    z-index: 2;
  }
}

.shopping-bag-icon {
  display: inline;

  @media (max-width: @screen-sm-max) {
    display: inline-block;
  }

  position: relative;

  .number {
    width: 16px;
    height: 16px;
    background-color: @brand-main-color;
    border-radius: 16px;
    text-align: center;
    .font-sans-serif;
    font-size: 10px;
    color: #ffffff;
    line-height: 15px;
    display: inline-block;
    position: absolute;
    top: -4px;
    .make-responsive-top(-4px, -4px, 15px, 7px);
    left: 15px;
  }
}

.waiting-message,
.final-message {
  display: none;
}

.waiting-room {
  #video-container {
    background: #0000004f;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
      width: 100% !important;
      height: 100%;
      position: relative;
      background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0) 90%), url('/img/bg-video.gif');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  #collection-container {
    opacity: 0;

    @media (max-width: 991px) {
      display: none !important;
    }
  }

  .product-list,
  .show-title,
  .sticky-product,
  #primary-video-container,
  #secondary-video-container,
  #pre-recorded,
  .big-play-button,
  .play-pause-button,
  .big-pause-button,
  .progress-container {
    display: none !important;
  }

  .waiting-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    z-index: 3;

    h1,
    h5,
    h4,
    p,
    span {
      color: white;
      text-align: center;
    }

    h4 {
      .font-sans-serif;
    }

    p {
      padding: 0 20px;
    }

    span,
    p {
      .font-sans-serif;
      font-weight: lighter;
      text-align: center;
    }

    button {
      background-color: @brand-main-color;
      width: 100%;
    }

    a {
      color: @brand-main-color ;
      text-decoration: none;
    }

    .counter {
      margin: 5px auto 0 auto;
      color: white;
      display: flex;
      align-items: center;

      span {
        background: white;
        color: gray;
        padding: 5px 4px;
        margin: 2px;
        width: 17.5px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      &.days {
        margin: 10px auto 0 auto;

        span {
          background: transparent !important;
          color: white;
          padding: 0;
          .font-serif;
        }

        div {
          text-transform: uppercase;
          margin: 0 11px 0 0;
        }
      }
    }
  }
  #chat-container {
    @media (max-width: 768px) {
      height: 215px!important;
      .messages-container {
        bottom: -12px;
      }
    }
  }
}

.final-state {
  #video-container {
    background: #0000004f;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
      width: 100% !important;
      height: 100%;
      position: relative;
      background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0) 90%), url('/img/bg-video.gif');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
    }
  }

  .progress-container,
  #primary-video-container,
  #secondary-video-container,
  #pre-recorded {
    display: none !important;
  }

  .final-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    padding: 15px;

    h1,
    h5,
    h4,
    p,
    span {
      color: white;
      .text-center;
      padding: 0;
    }

    h4 {
      .font-sans-serif;
    }

    p {
      padding: 0 20px;
    }

    span,
    p {
      .font-sans-serif;
      font-weight: lighter;
      text-align: center;
    }

    button {
      margin-top: 10px;
      background-color: @brand-main-color;
      width: 100%;
    }

    a {
      color: @brand-main-color ;
      text-decoration: none;
    }

    .counter {
      margin: 5px auto 0 auto;
      color: white;
      display: flex;
      align-items: center;

      span {
        background: white;
        color: gray;
        padding: 5px 4px;
        margin: 2px;
        width: 17.5px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      &.days {
        margin: 10px auto 0 auto;

        span {
          background: transparent !important;
          color: white;
          padding: 0;
          .font-serif;
        }

        div {
          text-transform: uppercase;
          margin: 0 11px 0 0;
        }
      }
    }
  }

  .waiting-message {
    display: none !important;
  }

  .show-title {
    top: 0;

    .top-video {
      display: none;
    }

    .top-container {
      justify-content: right;

      .show-influencer {
        display: none;
      }

      .circle-bg {
        display: none;
      }

      .circle-bg.basquet {
        display: flex;
      }
    }
  }

  .sticky-product,
  .big-play-button,
  .big-pause-button {
    display: none !important;
  }

  // #added-to-cart-container, .collection-info {
  //   display: none;
  // } this hide the cart from the thanks for watching view
}

.basquet {
  margin-right: 5px;
}

video::-webkit-media-controls-panel {
  display: fixed !important;
  opacity: 0 !important;
}

.product-info.product-btn {
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  img {
    z-index: -1;
  }
}
.show-view .video-overlay .show-container .container-input input#chat:-internal-autofill-selected {
  background-color: transparent !important;
}

.animation-message-hidden {
  display: none;
}

.show-draggable-container {
  cursor: pointer;
  .button-wrapper {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 27;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.563484768907563) 0%,
    rgba(0,0,0,0.4598433123249299) 45%, rgba(0,0,0,0) 100%);
    .close-btn {
      float: right;
      background-color: transparent;
      color: black;
      border: none;
      font-size: 16px;
      position: absolute;
      right: 0;
      z-index: 11111;
      top: 0;
      padding: 12px;
    }
    .open {
      padding: 10px 12px;
    }
    .close-btn, .open {
      width: 48px;
    }
  }
  position: fixed;
  top: inherit;
  left: inherit;
  bottom: 0;
  right: 0;
  width: 200px;
  height: auto;
  aspect-ratio: .5625;
  z-index: 26;
  overflow: hidden;
  box-shadow: 1px 1px 9px #00000061;
  @media (max-width:768px) {
    width: 135px;
    .close-btn, .open {
      width: 42px;
    }
    .active-icon {
      width: 35px!important;
      height: 35px!important;
      img {
        width: 20px;
      }
    }
  }
  .active-icon {
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    z-index: 3;
    background: #0000006b;
    border-radius: 50%;
    img {
      width: 24px;
    }
  }
}

.show-draggable {
  width: 200px;
  height: auto;
  aspect-ratio: .5625;
  object-fit: cover;
  top: 0;
  left: 0
}

.animation-message {
  position: absolute;
  z-index: 4;
  top: 142px;
  left: 0;
  .scale-up-hor-left {
    animation: scale-up-hor-left 1s ease 0s 1 normal forwards;
    padding: 7px;
    background: #ffffff9e;
    color: @button-color-hover;
    width: 200px;
    border: 2px solid white;
    border-left: 0;
    display: flex;
    p {
      font-size: 80%;
      font-style: italic;
      margin: 0;
      padding-left: 5px;
      span {
        .font-sans-serif-bold;
      }
    }
  }
  animation: fade-animate 1s ease 5s 1 normal forwards;
  /**
  * ----------------------------------------
  * animation
  * ----------------------------------------
  */
  @keyframes scale-up-hor-left {
    0% {
      transform: translateX(-340px);
    }

    100% {
      transform: translateX(0px);
    }
  }
  @keyframes fade-animate {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
.show-container.show-container-shadow {
  @media (min-width: 992px) and (max-width: 1024px) {
    @media (min-height: 992px) {
      height: 700px;
      overflow: hidden;
      #chat-container, #video-container,#collection-container {
        max-height: 100%!important;
      }
      #primary-video-container, #video-container {
        height: 100%!important;
      }
    }
  }
}
.modal.show-quick-view-modal {
  overflow-y: hidden;
  @media (min-width: 992px) and (max-width: 1024px) {
    @media (min-height: 992px) {
      .modal-dialog {
        display: flex;
        flex-direction: column;
      }
      .close-section {
        height: 100%;
      }
    }
  }
}
.promo-show {
  .cupons {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 70px;
    align-items: center;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.196);
    z-index: 5;
    width: max-content;
    @media (min-width: 992px) {
      left: 0;
    }
    .wrapper-icon {
      padding: 5px;
    }
    .wrapper-text {
      background-color: rgba(255, 255, 255, 0.396);
      display: flex;
      flex-direction: initial;
      font-size: 13px;
      .text-code {
        .font-sans-serif;
        padding: 5px;
        .discount {
          color: @themile-primary-dark;
          font-size: 16px;
          span {
            .font-sans-serif-bold;
            padding-right: 2px;
          }
        }
        .name {
          letter-spacing: 2px;
        }
      }
      .copy-icon {
        padding: 5px;
        cursor: pointer;
        display: flex;
        width: 40px;
        img {
          width: 100%;
        }
      }
    }
  }
  &.show-view .video-overlay #chat-container.posted-show .message-container {
    @media (max-width: 992px) {
      width: calc(100% - 152px);
    }
  }
}
